import Vue from 'vue';
import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Domain
import {
  OptionEntity,
} from '@/modules/my-investment/catalogs/allianz/option/domain/entities/option-entity';
import Translator from '@/modules/shared/domain/i18n/translator';
import {
  QuestionEntity,
} from '@/modules/my-investment/catalogs/allianz/question/domain/entities/question-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  TemplateQuestionnaireEntity,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/entities/template-questionnaire-entity';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class InvestorProfileInvestmentTypeViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.contract-savings.profiling.investment-profile';

  private readonly view!: Vue;

  is_loading = true;

  form_valid = false;

  conservative_items: Array<OptionEntity> = [];

  moderate_items: Array<OptionEntity> = [];

  aggressive_items: Array<OptionEntity> = [];

  object_format = {
    llave: '',
    valor: '',
    descripcion: '',
  }

  onboarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {
      investor_type_selected_profile: null,
      investor_type_selected: this.object_format,
      investor_type_moderate: this.object_format,
      investor_type_conservative: this.object_format,
      investor_type_aggressive: this.object_format,
    },
  };

  input_rules = {
    investor_type: [requiredRule],
  }

  step_name = 'InvestorProfileInvestmentType';

  public constructor(view: Vue) {
    this.view = view;
  }

  get can_continue() {
    return this.form_valid && !this.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  onInvestorTypeChanged = (investor_type: string) => {
    this.onboarding_step.payload.investor_type_selected_profile = investor_type;
    if (investor_type === 'Conservador') {
      this.onboarding_step.payload.investor_type_selected = {
        ...this.conservative_items[0],
      };
      this.onboarding_step.payload.investor_type_conservative = { ...this.conservative_items[0] };
      this.onboarding_step.payload.investor_type_moderate = { ...this.moderate_items[1] };
      this.onboarding_step.payload.investor_type_aggressive = { ...this.aggressive_items[1] };
    }
    if (investor_type === 'Moderado') {
      this.onboarding_step.payload.investor_type_selected = { ...this.moderate_items[0] };
      this.onboarding_step.payload.investor_type_moderate = { ...this.moderate_items[0] };
      this.onboarding_step.payload.investor_type_conservative = { ...this.conservative_items[1] };
      this.onboarding_step.payload.investor_type_aggressive = { ...this.aggressive_items[1] };
    }
    if (investor_type === 'Agresivo') {
      this.onboarding_step.payload.investor_type_selected = {
        ...this.aggressive_items[0],
      };
      this.onboarding_step.payload.investor_type_aggressive = { ...this.aggressive_items[0] };
      this.onboarding_step.payload.investor_type_moderate = { ...this.moderate_items[1] };
      this.onboarding_step.payload.investor_type_conservative = { ...this.conservative_items[1] };
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.onboarding_step.payload.internal_step = this.step_name;
    this.view.$emit('nextStep', this.onboarding_step);
  }

  loadOptions = (questions: Array<QuestionEntity>) => {
    if (questions.length) {
      this.conservative_items = questions[0].opciones;
      this.moderate_items = questions[1].opciones;
      this.aggressive_items = questions[2].opciones;
    }
  }

  setInputsDataFromStep = (onboarding_step: OnBoardingStepEntity) => {
    this.onboarding_step = { ...onboarding_step };
  }

  chargeOnLoadFinished = (
    load_finished: boolean,
    template: Array<TemplateQuestionnaireEntity>,
    onboarding_step: OnBoardingStepEntity,
  ) => {
    if (load_finished) {
      const questionnaire = template[0];
      const question_entities = questionnaire.secciones[3].preguntas;
      this.loadOptions(question_entities);
      this.setInputsDataFromStep(onboarding_step);
    }
  }

  initialize = async () => {
    this.is_loading = false;
  }
}
